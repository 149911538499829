import { AnimatePresence } from 'framer-motion'
import Image from 'next/image'
import PropTypes from 'prop-types'

import dimensions from '../../../../../utils/dimensions'
import isServer from '../../../../../utils/is-server'
import { BackgroundEl } from '../elements'

const Background = ({ current, image }) => {
  return (
    <AnimatePresence exitBeforeEnter>
      <BackgroundEl
        key={`background-${current}`}
        animate={{
          opacity: 1,
          transition: {
            duration: 1,
            ease: 'easeOut',
          },
        }}
        exit={{ opacity: 0 }}
        initial={{ opacity: isServer() ? 1 : 0 }}
      >
        <Image
          height={dimensions.vw((200 * 9) / 16)}
          priority
          quality={50}
          src={image}
          width={dimensions.vw(200)}
        />
      </BackgroundEl>
    </AnimatePresence>
  )
}

export default Background

Background.propTypes = {
  current: PropTypes.number.isRequired,
  image: PropTypes.string.isRequired,
}
