import ViewTracker from '../../../../components/tracking/view-tracker'
import { H4, Text } from '../../../../styles/typography'
import List from './components/list'
import { ArtistsEl } from './elements'

const Artists = () => {
  return (
    <ArtistsEl>
      <ViewTracker page='home' item='meet-the-livdemy-artists' />
      <H4>
        Meet the <br />
        LivDemy Artists
      </H4>
      <Text>
        Our Hall of Fame. Our pillars of musical knowledge. These greats of the
        greats have shared with us from their pool of wisdom, offering some of
        the best music classes on the internet. We have a deep respect for those
        who leave a musical legacy through LivDemy!
      </Text>
      <List />
    </ArtistsEl>
  )
}

export default Artists
