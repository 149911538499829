import { Avatar, TextareaAutosize } from '@material-ui/core'
import Image from 'next/image'
import { useMemo } from 'react'

import Carousel from '../../../../components/carousel'
import loader from '../../../../components/loader'
import ViewTracker from '../../../../components/tracking/view-tracker'
import { H5, H6, Text } from '../../../../styles/typography'
import dimensions from '../../../../utils/dimensions'
import getInitials from '../../../../utils/initials'
import HomeStore from '../../stores/home-store'
import { TestimonialsEl } from './elements'

const Testimonials = () => {
  const { reviews } = HomeStore.useContainer()

  const loading = useMemo(() => !reviews.data && !reviews.error, [
    reviews.data,
    reviews.error,
  ])
  const loadFailed = useMemo(() => Boolean(reviews.error), [reviews.error])

  return (
    <TestimonialsEl>
      <ViewTracker item='student-testimonials' page='home' />
      <H5>
        Student <span>Testimonials</span>
      </H5>
      <Text>
        Here are some words of encouragement we've received from our past
        students. We're excited to see our efforts go to the right places. To
        see how this has inspired music lovers across the globe. We're proud of
        our student stories. Share yours using <span>#LivDemy</span>!
      </Text>
      {loader(
        loading,
        loadFailed,
        <Carousel
          data={reviews.data}
          item={(review) => (
            <div key={review.id} className='review'>
              <div className='card'>
                <div className='artist'>
                  <div className='cover'>
                    {review?.artist?.cover && (
                      <Image
                        height={dimensions.dw(20)}
                        quality={30}
                        src={review.artist.cover}
                        width={dimensions.dw(20)}
                      />
                    )}
                  </div>
                  <div className='info'>
                    <H6>{review.artist.name}</H6>
                    <TextareaAutosize maxRows={2} value={review.course} />
                  </div>
                </div>
                <Text className='reviewText'>{review.text}</Text>
              </div>
              <div className='user'>
                <Avatar
                  alt={review.user?.name}
                  src={review.user?.picture}
                  variant='square'
                >
                  {getInitials(review.user?.name)}
                </Avatar>
                <Text>{review.user?.name}</Text>
              </div>
            </div>
          )}
          itemWidth={dimensions.vw(80) + dimensions.rem(2)}
        />,
      )}
    </TestimonialsEl>
  )
}

export default Testimonials
