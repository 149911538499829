import { IconButton } from '@material-ui/core'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import Link from 'next/link'

import Button from '../../../../components/button'
import BroadcastClass from '../../../../components/cards/broadcast-class/broadcast-class'
import Carousel from '../../../../components/carousel'
import ViewTracker from '../../../../components/tracking/view-tracker'
import { H3, Text } from '../../../../styles/typography'
import dimensions from '../../../../utils/dimensions'
import { ClassesControlsEl } from '../../elements'
import HomeStore from '../../stores/home-store'
import { UpcomingClassesEl } from './elements'
import getUpcomingClasses from './utils/classes'

const UpcomingClasses = () => {
  const {
    data: { masterclasses, workshops, btm, demo, qna, concert, gig },
  } = HomeStore.useContainer()

  const sessions = getUpcomingClasses(
    masterclasses,
    workshops,
    btm,
    demo,
    gig,
    qna,
    concert,
  )

  return (
    <UpcomingClassesEl>
      <ViewTracker item='live-classes' page='home' />
      <H3>Live Sessions</H3>
      <Text>
        Our live classes get you face-to-face with legendary artists, who would
        be happy to answer any inquisitive questions you may have on a
        particular class topic. Get your questions answered, chat with the
        artists, live! These have limited seats, so get one while you can.
      </Text>
      <Carousel
        controls={(inFocus, next, prev) => (
          <ClassesControlsEl>
            <div className='controls'>
              <IconButton disabled={inFocus === 0} onClick={() => prev()}>
                <ArrowForwardIosIcon />
              </IconButton>
              <IconButton
                disabled={inFocus === sessions.length - 1}
                onClick={() => next()}
              >
                <ArrowForwardIosIcon />
              </IconButton>
            </div>
            <Link href='/discover/live-sessions/all/all/all' passHref>
              <a>
                <Button theme='primary'>View More</Button>
              </a>
            </Link>
          </ClassesControlsEl>
        )}
        data={sessions}
        item={(data, params) => <BroadcastClass data={data} params={params} />}
        itemWidth={dimensions.vw(60) + dimensions.rem(2)}
      />
    </UpcomingClassesEl>
  )
}

export default UpcomingClasses
