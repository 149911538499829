const recordedClasses = [
  {
    artist: 'Ronkini Gupta',
    canSellRecording: true,
    cover:
      'https://livdemybucket.s3.amazonaws.com/Ronkini+Gupta+02.jpg%232021%231%2330%23undefined',
    currency: 'INR',
    discount: 25,
    endTime: 1612072800000,
    fee: 800,
    id: '-MP3WDawfyO152-BawZM',
    isSeries: false,
    medium: 'English, Hindi',
    name: 'Techniques of Improvisation in Khayal',
    startTime: 1598796000000,
    type: 'workshop',
    url: '/ronkinigupta/workshop/techniques-of-improvisation-in-khayal/1',
  },
  {
    artist: 'Rasika Shekar',
    canSellRecording: true,
    cover:
      'https://livdemybucket.s3.amazonaws.com/Rasika-Shekar-08.jpg%232020%238%2317%23undefined',
    currency: 'INR',
    discount: 25,
    endTime: 1598801400000,
    fee: 800,
    id: '-MEi6V1nFZxaZjc2sAwe',
    isSeries: false,
    medium: 'English, Tamil',
    name: 'The Bamboo Flute - Techniques and Practice Methods',
    startTime: 1598796000000,
    type: 'workshop',
    url:
      '/rasikashekar/workshop/the-bamboo-flute-techniques-and-practice-methods/1',
  },
  {
    artist: 'Hariharan',
    canSellRecording: true,
    cover:
      'https://livdemybucket.s3.amazonaws.com/13+%281%29.jpeg%232021%239%232%23undefined',
    currency: 'INR',
    discount: 25,
    endTime: 1632673800000,
    fee: 800,
    id: '-MiVVqaEMA3Z5Kf0ZeQn',
    isSeries: false,
    medium: 'Bengali (Bangla), English, Hindi',
    name: 'Ghazal - From Poem to Performance',
    startTime: 1632668400000,
    type: 'master-class',
    url: '/hariharan/masterclass/ghazal-from-poem-to-performance/1',
  },
  {
    artist: 'Salim Merchant',
    canSellRecording: true,
    cover:
      'https://livdemybucket.s3.amazonaws.com/salim.png%232021%235%231%23undefined',
    currency: 'INR',
    discount: 25,
    endTime: 1622394000000,
    fee: 800,
    id: '-MZNtF9dmhgGhirwTotw',
    isSeries: false,
    medium: 'English, Tamil',
    name: 'Deconstructing O Re Piya - Production, Composition and Arrangement',
    startTime: 1622388600000,
    type: 'master-class',
    url:
      '/salimmerchant/masterclass/deconstructing-o-re-piya-production-composition-and-arrangement/1',
  },
  {
    artist: 'Mahesh Kale',
    canSellRecording: true,
    cover:
      'https://livdemybucket.s3.amazonaws.com/DSC_0004Emkmkmk.JPG%232020%2311%2323%23undefined',
    currency: 'INR',
    discount: 25,
    endTime: 1607790600000,
    fee: 800,
    id: '-MGEXBr-g_kX86C8Pgup',
    isSeries: false,
    medium: 'English',
    name: 'Scaling up: Alankar methodology for fluency in music skills',
    startTime: 1607785200000,
    type: 'master-class',
    url:
      '/maheshkale/masterclass/scaling-up-alankar-methodology-for-fluency-in-music-skills/1',
  },
  {
    artist: 'Mahesh Raghvan',
    canSellRecording: true,
    cover:
      'https://livdemybucket.s3.amazonaws.com/Untitled+design.png%232021%238%2326%23undefined',
    currency: 'INR',
    discount: 25,
    endTime: 1589558400000,
    fee: 800,
    id: '-M6Zt5rXAfp9jJ_GDkwg',
    isSeries: false,
    medium: 'English',
    name: 'GeoShred Masterclass - Learn to play music on your iPad',
    startTime: 1589553000000,
    type: 'master-class',
    url:
      '/followingmahesh/masterclass/geoshred-masterclass-1-learn-to-play-music-on-the-ipad/1',
  },
]

export default recordedClasses
