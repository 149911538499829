import { animate, useMotionValue } from 'framer-motion'
import { useCallback, useState } from 'react'
import { createContainer } from 'unstated-next'

const CarouselStore = createContainer(() => {
  const x = useMotionValue(0)
  const [inFocus, setFocus] = useState(0)
  const [width, setWidth] = useState(0)
  const [length, setLength] = useState(0)

  const goTo = useCallback(
    (index) => {
      animate(x, -1 * (index * width), {
        type: 'spring',
        stiffness: 500,
        damping: 50,
        mass: 3,
      })

      setFocus(index)
    },
    [width, x],
  )

  const next = useCallback(() => {
    const nextIndex = Math.min(inFocus + 1, length - 1)

    goTo(nextIndex)
  }, [goTo, inFocus, length])

  const prev = useCallback(() => {
    const prevIndex = Math.max(inFocus - 1, 0)

    goTo(prevIndex)
  }, [goTo, inFocus])

  return {
    x,
    inFocus,
    width,
    length,
    setFocus,
    goTo,
    next,
    prev,
    setWidth,
    setLength,
  }
})

export default CarouselStore
