/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'

export const RecordedClassesEl = styled.div`
  position: relative;
  padding: 2.5em 0;
  margin: 2em 0;

  > h3 {
    padding: 0 1rem;
    line-height: 1.33;
  }

  > p {
    padding: 0 1rem;
    line-height: 1.5;
    margin: 2em 0;
    color: #a0a0a0;
  }
`
