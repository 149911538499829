import {
  always,
  append,
  applySpec,
  ascend,
  descend,
  filter,
  map,
  pathOr,
  pipe,
  propOr,
  slice,
  sort,
} from 'ramda'

import addId from '../../../utils/add-id'
import recordedClasses from './recorded-classes'

const landingParser = applySpec({
  artists: pipe(pathOr([], ['artists']), addId),
  btm: pipe(pathOr([], ['btm']), addId),
  concert: pipe(pathOr([], ['concert']), addId),
  demo: pipe(pathOr([], ['demo']), addId),
  gig: pipe(pathOr([], ['gig']), addId),
  liveCourses: pipe(pathOr([], ['liveCourses']), addId),
  masterclasses: pipe(pathOr([], ['masterclass']), addId),
  qna: pipe(pathOr([], ['qna']), addId),
  recordedClasses: always(recordedClasses),
  recordedCourses: pipe(
    pathOr([], ['recordedCourses']),
    slice(1, Infinity),
    append({
      artist: 'Milind Date',
      cover:
        'https://livdemybucket.s3.amazonaws.com/Milind+-+cover.png%232023%232%2327',
      currency: 'INR',
      discount: 0,
      duration: '',
      fee: 1500,
      name: 'Art of Playing Bansuri - 1',
      type: 'recorded',
      url: '/milinddate/course/art-of-playing-bansuri-1',
    }),
    addId,
  ),
  workshops: pipe(pathOr([], ['workshop']), addId),
})

export const premiumCourseParser = pipe(
  pathOr([], ['recordedCourses']),
  map(
    pipe(
      applySpec({
        name: pathOr('', ['info', 'title']),
        artist: pathOr('', ['teacherName']),
        type: pathOr('', ['type']),
        duration: pathOr(0, ['info', 'noOfClasses']),
        discount: pathOr(0, ['discount']),
        fee: pathOr(0, ['fee', 'amount']),
        cover: pathOr('', ['info', 'courseCover', 'location']),
        isGroupCourse: pathOr(false, ['isGroupCourse']),
        url: ({ teacherLivdemyId, info: { courseUrl } }) =>
          `/${teacherLivdemyId}/course/${courseUrl}`,
      }),
    ),
  ),
  addId,
)
export const landingPageArtist = applySpec({
  cover: propOr('', 'coverImage'),
  id: propOr('', 'id'),
  livdemyId: propOr('', 'livdemyId'),
  name: propOr('', 'name'),
})

export const landingBannerParser = pipe(
  addId,
  map((x) => ({ ...x, position: Number(x.position) })),
  sort(ascend(propOr(0, 'position'))),
)

export const landingReviewsParser = pipe(
  addId,
  sort(descend(pathOr(0, ['text', 'length']))),
)

export default landingParser
