import { filter, flatten, pipe, propOr, slice } from 'ramda'
import eventsSort from '../../../../../utils/events-sort'

const getResumeClasses = () => {
  const data = JSON.parse(localStorage.getItem('resumeVideos')) || []
  const newData = data.map((item) => {
    return item.courseData
  })
  return newData
}

export default getResumeClasses
