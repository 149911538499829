import {
  filter,
  flatten,
  gt,
  ifElse,
  lt,
  not,
  pathOr,
  pipe,
  propOr,
  slice,
} from 'ramda'

import eventsSort from '../../../../../utils/events-sort'

const getUpcomingClasses = (...classes) => {
  return pipe(
    flatten,
    eventsSort,
    filter(pipe(propOr(false, 'comingSoon'), not)),
    ifElse(
      pipe(pathOr(0, ['5', 'endTime']), gt(Date.now())),
      slice(0, 6),
      filter(pipe(propOr(0, 'endTime'), lt(Date.now()))),
    ),
  )(classes)
}

export default getUpcomingClasses
