/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'

export const CarouselEl = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  overflow: hidden;
`
