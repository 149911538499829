import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import Link from 'next/link'
import Button from '../../../../../components/button'
import { Text } from '../../../../../styles/typography'

const ViewAll = () => {
  return (
    <Link href='/discover/artists'>
      <Button className='viewAll'>
        <Text>
          View All
          <br />
          Artists
        </Text>
        <ChevronRightIcon />
      </Button>
    </Link>
  )
}

export default ViewAll
