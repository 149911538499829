import PropTypes from 'prop-types'
import { useEffect, useRef } from 'react'

import GoogleAnalyticsTrackingStore from '../../modules/tracking/stores/google-analytics-tracking-store'

require('intersection-observer')

const ViewTracker = ({ page, item }) => {
  const el = useRef()
  const observer = useRef()
  const called = useRef(false)

  const { logEvent } = GoogleAnalyticsTrackingStore.useContainer()

  useEffect(() => {
    observer.current = new IntersectionObserver(
      ([{ isIntersecting }]) => {
        if (!called.current && isIntersecting) {
          called.current = true
          observer.current?.disconnect()
          observer.current = null

          logEvent({
            action: 'scrolled to',
            category: 'usage',
            label: `${page} || ${item}`,
            nonInteraction: true,
          })
        }
      },
      {
        rootMargin: '0px',
        threshold: 1,
      },
    )

    observer.current.observe(el.current)

    return () => {
      observer.current?.disconnect()
      observer.current = null
    }
  }, [item, logEvent, page])

  return <div ref={el} />
}

ViewTracker.propTypes = {
  item: PropTypes.string.isRequired,
  page: PropTypes.string.isRequired,
}

export default ViewTracker
