import Head from 'next/head'

const Meta = () => {
  return (
    <Head>
      <title>Your Live Music Academy | LivDemy</title>
      <meta
        content='Your Live Music Academy | LivDemy'
        name='og:title'
        property='og:title'
      />
      <meta
        content='Livdemy connects you to a music teacher online and learn music from them. Learn vocals, guitar, piano or any instrument from the comfort of your home.'
        name='description'
        property='description'
      />
      <meta
        content='Livdemy connects you to a music teacher online and learn music from them. Learn vocals, guitar, piano or any instrument from the comfort of your home.'
        name='og:description'
        property='og:description'
      />
      <meta
        content='LivDemy,livdemy,live,learn music,learn music online,music'
        name='keywords'
        property='keywords'
      />
      <meta
        content='/logo/logo-white-background.png'
        name='og:image'
        property='og:image'
      />
      <meta
        content='/logo/logo-white-background.png'
        name='og:image:secure'
        property='og:image:secure'
      />
      <meta
        content='/logo/logo-white-background.png'
        name='og:image:secure_url'
        property='og:image:secure_url'
      />
    </Head>
  )
}

export default Meta
