import localforage from 'localforage'
import { identity } from 'ramda'
import { useEffect, useState } from 'react'
import { createContainer } from 'unstated-next'

import ipInfo from '../../../api/misc/ip-info'
import useSwr from '../../../hooks/use-swr'
import { USER_PREFERENCES_KEY } from '../../dashboard/settings/preferences/stores/peferences-store'
import landingParser, {
  landingBannerParser,
  landingReviewsParser,
} from '../utils/parser'

const HomeStore = createContainer((initialData) => {
  const [localCurrency, setLocalCurrency] = useState('INR')

  useEffect(() => {
    ;(async () => {
      setLocalCurrency(
        (await localforage
          .getItem(USER_PREFERENCES_KEY)
          .then((pref) => pref?.currency)
          .catch(() => ``)) || (await ipInfo())?.currency,
      )
    })()
  }, [])

  const { data, error } = useSwr({
    initialData: initialData?.data,
    parser: landingParser,
    server: 'public-cache',
    url: `home/landing`,
  })

  const { data: bannerData, error: bannerError } = useSwr({
    initialData: initialData?.banner,
    parser: landingBannerParser,
    server: 'public-cache',
    url: `banner/all`,
  })

  const exr = useSwr({
    initialData: 0,
    parser: identity,
    url: `currency?local=${localCurrency}&foreign=INR`,
  })

  const reviews = useSwr({
    parser: landingReviewsParser,
    url: `review/v3/WHITELISTED/limit/15`,
  })

  return {
    bannerData,
    data,
    exr,
    loadFailed: Boolean(error || bannerError),
    loading: (!data && !error) || (!bannerData && !bannerError),
    localCurrency,
    reviews,
  }
})

export default HomeStore
