import homeImg from '../../../../../assets/illustrations/home.svg'
import liveChatImg from '../../../../../assets/illustrations/live-chat.svg'
import liveClassImg from '../../../../../assets/illustrations/live-class.svg'

const features = [
  {
    key: `live-class`,
    graphic: liveChatImg,
    title: `Live Classes`,
    text: `Masterclasses where you get to engage in discussions with the artist!`,
  },
  {
    key: `recorded-course`,
    graphic: liveClassImg,
    title: `Recorded Courses`,
    text: `Master crafted coursework that gets you confident in your musical skills.`,
  },
  {
    key: `anywhere`,
    graphic: homeImg,
    title: `Learn from anywhere`,
    text: `Cull your curiosity from the comfort of your home, or on the move.`,
  },
]

export default features
