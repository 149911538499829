import loader from '../../components/loader'
import useBack from '../../modules/navigation/hooks/use-back'
import withStore from '../../utils/with-store'
import About from './components/about'
import Artists from './components/artists'
import Header from './components/header'
import Meta from './components/meta'
import RecordedClasses from './components/recorded-classes'
import RecordedCourses from './components/recorded-courses'
import ResumeClasses from './components/resume-classes'
import Testimonials from './components/testimonials'
import UpcomingClasses from './components/upcoming-classes'
import { HomeEl } from './elements'
import HomeStore from './stores/home-store'

const Home = () => {
  useBack('')
  const { loading, loadFailed } = HomeStore.useContainer()

  return loader(
    loading,
    loadFailed,
    <HomeEl>
      <Meta />
      <Header />
      <ResumeClasses />
      <RecordedClasses />
      <UpcomingClasses />
      <RecordedCourses />
      <About />
      <Artists />
      <Testimonials />
    </HomeEl>,
  )
}

export default withStore([HomeStore])(Home)
