/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'

import { CarouselEl } from '../../../../../components/carousel/elements'
import { accent, background2 } from '../../../../../styles/colors'
import { hFlex, singleLineText, square } from '../../../../../styles/style-helpers'

export const TestimonialsEl = styled.div`
  position: relative;
  padding: 2.5em 0;
  margin: 2em 0 0;

  > h5 {
    padding: 0 1rem;
    line-height: 1.33;
    color: #a0a0a0;

    span {
      display: block;
      font-size: 1.5em;
      color: #e7e7e7;
    }
  }

  > p {
    padding: 0 1rem;
    line-height: 1.5;
    margin: 2em 0;
    color: #a0a0a0;

    span {
      color: ${accent};
    }
  }

  > ${CarouselEl} {
    .review {
      width: calc(100% - 2em);
      margin: 1em auto;
      padding: 0.5em;

      .card {
        position: relative;
        background: ${background2};
        padding: 1.5em;
        border-radius: 0.5em;

        &:after {
          content: '';
          ${square('1em')};
          background: ${background2};
          position: absolute;
          top: 100%;
          left: 1em;
          transform: translateY(-50%) rotateZ(45deg);
        }

        .artist {
          width: 100%;
          ${hFlex};
          align-items: unset;
          padding-bottom: 1em;
          margin-bottom: 1em;
          border-bottom: 1px solid #555;

          .cover {
            ${square('12.5vw')};
            margin-right: 1em;
            border-radius: 0.5em;
            overflow: hidden;
          }

          .info {
            position: relative;
            flex: 1;
            width: calc(100% - 12.5% - 2em);

            > h6 {
              font-weight: bold;
              color: ${accent};
              ${singleLineText};
            }

            > textarea {
              margin-top: 0.5em;
              color: #999;
              width: 100%;
              resize: none;
              background: none;
              border: none;
              font-family: inherit;
            }
          }
        }

        .reviewText {
          line-height: 1.5;
        }
      }

      .user {
        width: 100%;
        ${hFlex};
        justify-content: flex-end;
        padding: 0.5em;
        margin-top: 1em;

        .MuiAvatar-root {
          height: 2.25em;
          width: 2.25em;
          font-size: 1em;
          text-transform: uppercase;
          letter-spacing: 0.025em;
          margin-right: 0.5em;
          border-radius: 0.35em;
        }

        > p {
          flex: 1;
          ${singleLineText};
          text-transform: capitalize;
        }
      }
    }
  }
`
