import Link from 'next/link'
import PublicRecordedCourse from '../../../../components/cards/course/public/recorded'
import List from '../../../../components/list'
import ViewTracker from '../../../../components/tracking/view-tracker'
import { H5, Text } from '../../../../styles/typography'
import HomeStore from '../../stores/home-store'
import { RecordedCoursesEl } from './elements'
import { ShowMoreEl } from '../../../../components/list/elements'

const RecordedCourses = () => {
  const { data } = HomeStore.useContainer()

  return (
    <RecordedCoursesEl>
      <ViewTracker page='home' item='recorded-courses' />
      <H5>
        LivDemy <span>Recorded Courses</span>
      </H5>
      <Text>
        We work with the artists to create a curriculum that is precise, up to
        date, and contains the most intimate knowledge of the musical arts. All
        in an easy to absorb, modular format. Learn at your own pace! Explore
        our excellent courses.
      </Text>
      <List
        data={data?.recordedCourses}
        item={(itemData) => <PublicRecordedCourse data={itemData} />}
        defaultCount={2}
        hideShowMore={true}
      />
      <Link href='/premium'>
        <ShowMoreEl className='showMore' role='button'>
          <p>Show More</p>
        </ShowMoreEl>
      </Link>
    </RecordedCoursesEl>
  )
}

export default RecordedCourses
