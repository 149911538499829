/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'
import { background1 } from '../../../styles/colors'
import { hFlex } from '../../../styles/style-helpers'

export const HomeEl = styled.div`
  position: relative;
  width: 100%;
  background: ${background1};
  color: #e7e7e7;
`
export const ClassesControlsEl = styled.div`
  width: 100%;
  padding: 1em;
  ${hFlex};
  justify-content: space-between;

  .controls {
    & > button {
      background: #333;
      border-radius: 0.5em;
      font-size: 1em;
      margin-right: 0.25em;
      transform: scale(0.85);

      &.Mui-disabled {
        opacity: 0.5;
      }

      svg {
        fill: white;
      }

      &:first-of-type {
        transform: scale(-0.85);
      }
    }
  }
`
