/* eslint-disable import/prefer-default-export */
import { motion } from 'framer-motion'
import styled from 'styled-components'

import { accent } from '../../../styles/colors'
import { overlay, vFlex } from '../../../styles/style-helpers'

export const PublicRecordedCourseEl = styled(motion.div)`
  display: block;
  width: 100%;
  padding: 0 1em;
  margin: 3.5em 0 0;

  .content {
    height: 100%;
    width: 100%;

    .cover {
      position: relative;
      width: 100%;
      padding-top: ${(100 * 9) / 16}%;
      margin-bottom: 1em;
      background: #232323;
      border-radius: 0.25em;
      overflow: hidden;

      > div {
        position: absolute !important;
        ${overlay};

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .info {
      width: 100%;
      flex: 1;
      ${vFlex};

      h5 {
        width: 100%;
        line-height: 1.33;
      }

      p {
        width: 100%;
        line-height: 1.33;
        margin-top: 0.25em;
        flex: 1;

        span {
          color: ${accent};
        }
      }
    }
  }
`
