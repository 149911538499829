import { IconButton } from '@material-ui/core'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import Link from 'next/link'
import { useMemo } from 'react'

import Button from '../../../../components/button'
import BroadcastClass from '../../../../components/cards/broadcast-class/broadcast-class'
import Carousel from '../../../../components/carousel'
import ViewTracker from '../../../../components/tracking/view-tracker'
import { H3, Text } from '../../../../styles/typography'
import dimensions from '../../../../utils/dimensions'
import { ClassesControlsEl } from '../../elements'
import HomeStore from '../../stores/home-store'
import { RecordedClassesEl } from './elements'

const RecordedClasses = () => {
  const {
    data: { recordedClasses },
    exr,
    localCurrency,
  } = HomeStore.useContainer()

  const classes = useMemo(
    () =>
      recordedClasses.map((cls) => ({
        ...cls,
        currency: localCurrency,
        fee: cls.fee * exr.data,
      })),
    [exr.data, localCurrency, recordedClasses],
  )

  return (
    <RecordedClassesEl>
      <ViewTracker item='recorded-classes' page='home' />
      <H3>Class Recordings</H3>
      <Text>
        Get lifetime access to the recordings of our masterclasses and
        workshops. Watch and re-watch them as my times as you want based on your
        convenience and availability.
      </Text>
      <Carousel
        controls={(inFocus, next, prev) => (
          <ClassesControlsEl>
            <div className='controls'>
              <IconButton disabled={inFocus === 0} onClick={() => prev()}>
                <ArrowForwardIosIcon />
              </IconButton>
              <IconButton
                disabled={inFocus === classes.length - 1}
                onClick={() => next()}
              >
                <ArrowForwardIosIcon />
              </IconButton>
            </div>
            <Link
              href='/discover/live-sessions/all/all/with-recording'
              passHref
            >
              <a>
                <Button theme='primary'>View More</Button>
              </a>
            </Link>
          </ClassesControlsEl>
        )}
        data={classes}
        item={(data, params) => <BroadcastClass data={data} params={params} />}
        itemWidth={dimensions.vw(60) + dimensions.rem(2)}
      />
    </RecordedClassesEl>
  )
}

export default RecordedClasses
