import { AnimatePresence, motion } from 'framer-motion'
import PropTypes from 'prop-types'

import { H6, S05 } from '../../../../../styles/typography'
import isServer from '../../../../../utils/is-server'
import { InfoEl } from '../elements'

const Info = ({ current, title, subTitle }) => {
  return (
    <AnimatePresence exitBeforeEnter>
      <InfoEl>
        <H6>
          {title.map((text, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <span key={`title-line-${i}-${current}`}>
              <motion.span
                animate={{
                  opacity: 1,
                  transition: {
                    delay: i * 0.2,
                    duration: 0.66,
                    ease: 'easeOut',
                  },
                  y: 0,
                }}
                initial={{
                  opacity: isServer() ? 1 : 0,
                  y: isServer() ? 0 : 24,
                }}
              >
                {text}
              </motion.span>
            </span>
          ))}
        </H6>
        <S05
          key={`subtitle-${current}`}
          animate={{
            opacity: 0.66,
            transition: {
              delay: title.length * 0.2 + 0.33,
              duration: 1,
              ease: 'easeOut',
            },
          }}
          as={motion.p}
          initial={{ opacity: isServer() ? 0.66 : 0 }}
        >
          {subTitle.join(' ')}
        </S05>
      </InfoEl>
    </AnimatePresence>
  )
}

export default Info

Info.propTypes = {
  current: PropTypes.number.isRequired,
  subTitle: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.arrayOf(PropTypes.string).isRequired,
}
