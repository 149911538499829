import Image from 'next/image'
import Link from 'next/link'
import PropTypes from 'prop-types'
import LinesEllipsis from 'react-lines-ellipsis'

import { H5, Text } from '../../../../styles/typography'
import dimensions from '../../../../utils/dimensions'
import { PublicRecordedCourseEl } from '../../elements/public-recorded-course'

const PublicRecordedCourse = ({
  data: { key, id, cover, name, artist, url },
}) => {
  return (
    <Link href={url} passHref>
      <a>
        <PublicRecordedCourseEl key={key || id}>
          <div className='content'>
            <div className='cover'>
              {cover && (
                <Image
                  height={dimensions.dw(60)}
                  priority
                  quality={50}
                  src={cover}
                  width={dimensions.dw(60)}
                />
              )}
            </div>
            <div className='info'>
              <LinesEllipsis component={H5} maxLine='2' text={name} />
              <Text>
                By <span>{artist}</span>
              </Text>
            </div>
          </div>
        </PublicRecordedCourseEl>
      </a>
    </Link>
  )
}

export default PublicRecordedCourse

PublicRecordedCourse.propTypes = {
  data: PropTypes.shape({
    artist: PropTypes.string.isRequired,
    cover: PropTypes.string.isRequired,
    id: PropTypes.string,
    key: PropTypes.string,
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
}
