import { IconButton } from '@material-ui/core'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import { motion } from 'framer-motion'
import PropTypes from 'prop-types'

import { S05 } from '../../../../../styles/typography'
import { NavigationEl } from '../elements'

const Navigation = ({ previous, next, current, total, duration }) => {
  return (
    <NavigationEl>
      <div className='progress'>
        <motion.div
          key={`progress-bar-${current}`}
          animate='full'
          className='bar'
          initial='zero'
          transition={{ duration: duration / 1000 }}
          variants={{ full: { width: '100%' }, zero: { width: 0 } }}
        />
      </div>
      <IconButton onClick={previous}>
        <ArrowBackIosIcon />
      </IconButton>
      <S05>
        {current}/{total}
      </S05>
      <IconButton onClick={next}>
        <ArrowForwardIosIcon />
      </IconButton>
    </NavigationEl>
  )
}

export default Navigation

Navigation.propTypes = {
  current: PropTypes.number.isRequired,
  duration: PropTypes.number.isRequired,
  next: PropTypes.func.isRequired,
  previous: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
}
