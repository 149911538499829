/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'

export const RecordedCoursesEl = styled.div`
  position: relative;
  width: 100%;
  padding: 2.5em 0;

  > h5 {
    padding: 0 1rem;
    line-height: 1.33;
    color: #a0a0a0;

    span {
      display: block;
      font-size: 1.5em;
      color: #e7e7e7;
    }
  }

  > p {
    padding: 0 1rem;
    line-height: 1.5;
    margin: 2em 0;
    color: #a0a0a0;
  }

  .actions {
    font-size: 0.8em;
  }
`
