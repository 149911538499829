import ms from 'ms'
import { useEffect, useMemo, useRef, useState } from 'react'
import HomeStore from '../../stores/home-store'
import Action from './components/action'
import Background from './components/background'
import Info from './components/info'
import Navigation from './components/navigation'
import { HeaderEl } from './elements'

const SLIDE_DURATION = ms('6s')

const Header = () => {
  const timeout = useRef()
  const { bannerData } = HomeStore.useContainer()

  const [index, setIndex] = useState(0)

  useEffect(() => {
    timeout.current = setTimeout(() => {
      setIndex((index + 1) % bannerData.length)
    }, SLIDE_DURATION)

    return () => timeout.current && clearTimeout(timeout.current)
  }, [bannerData, index])

  const current = useMemo(() => bannerData[index], [bannerData, index])

  return (
    <HeaderEl>
      <div className='carousel'>
        <div className='content'>
          <Background current={index} image={current.image} />
          <Info
            current={index}
            title={current.heading}
            subTitle={current.para}
          />
          <div className='row'>
            <Action
              current={index}
              title={current.heading}
              link={current.cta.route}
              text={current.cta.name}
            />
            <Navigation
              key={`navigation-${current}`}
              duration={SLIDE_DURATION}
              current={index + 1}
              total={bannerData.length}
              previous={() => setIndex((old) => (old || bannerData.length) - 1)}
              next={() => setIndex((old) => (old + 1) % bannerData.length)}
            />
          </div>
        </div>
      </div>
    </HeaderEl>
  )
}

export default Header
