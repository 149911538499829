import { capitalize } from '@material-ui/core'
import { AnimatePresence } from 'framer-motion'
import Link from 'next/link'
import PropTypes from 'prop-types'

import Button from '../../../../../components/button'
import MixpanelTrackingStore from '../../../../../modules/tracking/stores/mixpanel-tracking-store'
import isServer from '../../../../../utils/is-server'
import { ActionEl } from '../elements'

const Action = ({ title, current, link, text }) => {
  const { trackEvent } = MixpanelTrackingStore.useContainer()

  return (
    <AnimatePresence exitBeforeEnter>
      <ActionEl
        key={`action-${current}`}
        animate='show'
        exit='hide'
        initial={{ opacity: isServer() ? 'show' : 'hide' }}
        variants={{
          hide: { opacity: 0 },
          show: { opacity: 1, transition: { delay: 0.66 } },
        }}
      >
        {link.startsWith('http') ? (
          <a href={link} rel='noopener noreferrer' target='_blank'>
            <Button
              onClick={() => {
                trackEvent('home-banner-cta-clicked', { link, title })
              }}
              theme='primary'
            >
              {capitalize(text) || 'View details'}
            </Button>
          </a>
        ) : (
          <Link href={link} passHref>
            <a>
              <Button
                onClick={() => {
                  trackEvent('home-banner-cta-clicked', { link, title })
                }}
                theme='primary'
              >
                {capitalize(text) || 'View details'}
              </Button>
            </a>
          </Link>
        )}
      </ActionEl>
    </AnimatePresence>
  )
}

export default Action

Action.propTypes = {
  current: PropTypes.number.isRequired,
  link: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  title: PropTypes.arrayOf(PropTypes.string).isRequired,
}
