import { Text } from '../../../../../styles/typography'

const Intro = () => {
  return (
    <div className='intro'>
      <Text>
        Dive into a world of musical education and true learning. Obtain
        distilled musical wisdom from the legends themselves!
      </Text>
    </div>
  )
}

export default Intro
