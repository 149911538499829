import Image from 'next/image'
import Link from 'next/link'
import PropTypes from 'prop-types'
import dimensions from '../../../../../utils/dimensions'

const Artist = ({ data: { id, cover, name, livdemyId } }) => {
  return (
    <Link href={`/${livdemyId}`}>
      <div className='artist' key={id}>
        {cover && (
          <Image
            src={cover}
            alt={name}
            height={dimensions.dw(50)}
            width={dimensions.dw(50)}
            quality={66}
          />
        )}
      </div>
    </Link>
  )
}

export default Artist

Artist.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    cover: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    livdemyId: PropTypes.string.isRequired,
  }).isRequired,
}
