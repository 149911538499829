import { useEffect } from 'react'
import TopBarStore from '../stores/top-bar-store'

const useBack = (previousUrl) => {
  const { setBackUrl } = TopBarStore.useContainer()

  useEffect(() => {
    setBackUrl(previousUrl)

    return () => setBackUrl('')
  }, [previousUrl, setBackUrl])
}

export default useBack
