/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'
import { background2 } from '../../../../../styles/colors'
import { hFlex, vFlex } from '../../../../../styles/style-helpers'
import background from '../../../../../utils/background'

export const AboutEl = styled.div`
  position: relative;
  margin-top: 5em;

  .heading {
    > h5 {
      padding: 0 1rem;
      line-height: 1.33;
      font-size: 1.5em;
      color: #e7e7e7;
    }
  }

  .intro {
    > p {
      padding: 0 1rem;
      line-height: 1.5;
      margin: 2em 0;
      color: #a0a0a0;
    }
  }

  .features {
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: unset;
    padding: 1em;
  }
`

export const FeatureEl = styled.div`
  position: relative;
  width: 100%;
  padding: 0.75em;
  border-radius: 0.25em;
  margin-bottom: 2em;
  ${hFlex};
  align-items: unset;
  background: ${background2};

  .graphic {
    display: block;
    flex-shrink: 0;
    width: 25vw;
    height: 20vw;
    ${({ graphic }) => background(graphic, 'contain')};
    margin-right: 1em;
    background-size: 85%;
  }

  .details {
    flex: 1;
    ${vFlex};
    align-items: flex-start;

    .title {
      margin-bottom: 0.5em;
    }

    .text {
      line-height: 1.33;
      color: #a0a0a0;
      letter-spacing: 0.0125em;
    }
  }
`
