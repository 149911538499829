import { AnimatePresence } from 'framer-motion'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

import withStore from '../../utils/with-store'
import Item from './components/item'
import { CarouselEl } from './elements'
import CarouselStore from './stores/carousel-store'

const Carousel = ({ data, item, itemWidth, controls, defaultIndex }) => {
  const [start] = useState(defaultIndex)

  const {
    inFocus,
    next,
    prev,
    setWidth,
    setLength,
    goTo,
  } = CarouselStore.useContainer()

  useEffect(() => {
    setWidth(itemWidth)
    setLength(data.length)
  }, [data.length, itemWidth, setLength, setWidth])

  useEffect(() => {
    setTimeout(() => {
      goTo(start)
    }, 300)
  }, [goTo, start])

  return (
    <AnimatePresence>
      <>
        <CarouselEl>
          {data.map((entry, index) => (
            <Item
              key={`item-${entry.id}`}
              data={entry}
              index={index}
              item={item}
              items={data.length}
              width={itemWidth}
            />
          ))}
        </CarouselEl>
        {controls(inFocus, next, prev)}
      </>
    </AnimatePresence>
  )
}

export default withStore([CarouselStore])(Carousel)

Carousel.propTypes = {
  controls: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })).isRequired,
  defaultIndex: PropTypes.number,
  item: PropTypes.func.isRequired,
  itemWidth: PropTypes.number.isRequired,
}

Carousel.defaultProps = {
  controls: () => null,
  defaultIndex: 0,
}
