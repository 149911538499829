/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'
import { hFlex, square, vFlex } from '../../../../../styles/style-helpers'

export const ArtistsEl = styled.div`
  position: relative;
  padding: 5em 0 2em;
  margin: 2em 0 0;

  > h4 {
    padding: 0 1rem;
    line-height: 1.33;
    color: #e7e7e7;
  }

  > p {
    padding: 0 1rem;
    line-height: 1.5;
    margin: 2em 0;
    color: #a0a0a0;
  }

  .list {
    width: 100%;
    padding: 0 1em;
    margin: 2em 0;

    .row {
      ${hFlex};
      width: 100%;
      height: 40vw;
      margin-bottom: 0.5em;

      &.tall {
        height: 50vw;
      }

      .artist {
        ${square('40vw')};
        height: 100%;
        background: #232323;
        border-radius: 0.25em;
        overflow: hidden;

        div {
          width: 100%;
          height: 100%;

          img {
            object-fit: cover;
          }
        }

        &:last-of-type {
          flex: 1;
          margin-left: 0.5em;
        }

        &:first-of-type {
          margin-left: 0;
        }
      }

      .viewAll {
        margin-left: 0.5em;
        ${square('40vw')};
        background: #232323;
        border-radius: 0.25em;
        color: white;

        .MuiButton-label {
          height: 100%;
          ${vFlex};
          align-items: flex-end;
          flex-wrap: wrap;
          padding: 0.75em;

          p {
            flex: 1;
            width: 100%;
            text-align: left;
            text-transform: uppercase;
            letter-spacing: 0.025em;
          }

          .MuiSvgIcon-root {
            border: 1px solid #fff;
            border-radius: 50%;
            font-size: 1.75em;
          }
        }
      }
    }
  }
`
