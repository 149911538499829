import ViewTracker from '../../../../components/tracking/view-tracker'
import Features from './components/features'
import Heading from './components/heading'
import Intro from './components/intro'
import { AboutEl } from './elements'

const About = () => {
  return (
    <AboutEl>
      <ViewTracker page='home' item='about' />
      <Heading />
      <Intro />
      <Features />
    </AboutEl>
  )
}

export default About
