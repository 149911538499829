import HomeStore from '../../../stores/home-store'
import { landingPageArtist } from '../../../utils/parser'
import Artist from './artist'
import ViewAll from './view-all'

const List = () => {
  const { data } = HomeStore.useContainer()

  return (
    <div className='list'>
      <div className='row'>
        <Artist data={landingPageArtist(data.artists[0])} />
        <Artist data={landingPageArtist(data.artists[1])} />
      </div>
      <div className='row tall'>
        <Artist data={landingPageArtist(data.artists[2])} />
      </div>
      <div className='row'>
        <Artist data={landingPageArtist(data.artists[3])} />
        <ViewAll />
      </div>
    </div>
  )
}

export default List
