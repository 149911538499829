import { H5 } from '../../../../../styles/typography'

const Heading = () => {
  return (
    <div className='heading'>
      <H5>
        Learn from the
        <br />
        World's Best Artists
      </H5>
    </div>
  )
}

export default Heading
