/* eslint-disable import/prefer-default-export */
import { motion } from 'framer-motion'
import styled from 'styled-components'

import { background2 } from '../../../../../styles/colors'
import { hFlex, overlay, vFlex } from '../../../../../styles/style-helpers'

export const HeaderEl = styled.div`
  position: relative;
  width: 100%;
  height: 32em;
  margin-bottom: 5em;

  .carousel {
    position: relative;
    width: 100%;
    height: 100%;
    padding-top: ${(100 * 9) / 16}%;

    .content {
      ${overlay};
      padding: 1em;
      background: #000;
      ${vFlex};
    }
  }

  .row {
    width: 100%;
    margin-top: 1em;
    ${hFlex};
  }
`

export const BackgroundEl = styled(motion.div)`
  position: relative;
  width: 100%;
  padding-top: ${(100 * 3) / 4}%;
  z-index: 1;
  ${hFlex};
  border-radius: 0.75em;
  overflow: hidden;
  background: ${background2};
  margin-bottom: 0.5em;

  > div {
    ${overlay};
    position: absolute !important;

    img {
      object-fit: cover;
      object-position: 100% 50%;
    }
  }
`

export const InfoEl = styled(motion.div)`
  position: relative;
  flex: 1;
  height: 100%;
  width: 100%;
  color: white;

  h6 {
    font-weight: bold;
    margin-bottom: 1em;

    span {
      display: block;
      position: relative;
    }

    & > span {
      overflow: hidden;
    }
  }

  p {
    line-height: 1.33;
  }
`

export const ActionEl = styled(motion.div)`
  margin-right: 1em;
  font-size: 0.85em;

  > .MuiButton-root {
    width: 10em;
    font-size: 0.88em;
    padding: 0.5em;

    &:empty {
      display: none;
    }
  }
`

export const NavigationEl = styled.div`
  position: relative;
  flex: 1;
  width: 100%;
  ${hFlex};

  .progress {
    flex: 1;
    width: 100%;
    height: 2px;
    background: #777;
    border-radius: 2px;
    overflow: hidden;
    margin: 0 1.5em;

    .bar {
      height: 100%;
      background: #e7e7e7;
    }
  }

  > p {
    letter-spacing: 0.1em;
  }
`
