import { applySpec, pipe, propOr } from 'ramda'

import generateStaticProps from '../api/configuration/helper/generate-static-props'
import Home from '../app/home'
import landingParser, { landingBannerParser } from '../app/home/utils/parser'

export default Home

export async function getStaticProps() {
  return generateStaticProps(
    [`home/landing`, `banner/all`],
    applySpec({
      banner: pipe(propOr([], '1'), landingBannerParser),
      data: pipe(propOr({}, '0'), landingParser),
    }),
    {
      server: 'public-cache',
    },
  )
}
