import { animate, motion } from 'framer-motion'
import PropTypes from 'prop-types'

import carouselConfig from '../config'
import CarouselStore from '../stores/carousel-store'

const { DRAG_OFFSET_THRESHOLD, DRAG_VELOCITY_THRESHOLD } = carouselConfig

const Item = ({ index, data, items, width, item }) => {
  const { x, inFocus, setFocus } = CarouselStore.useContainer()

  return (
    <motion.div
      key={`item-${data.key}`}
      drag='x'
      dragDirectionLock
      dragMomentum={false}
      onDragEnd={(event, info) => {
        setFocus((current) => {
          let updated = current

          const angle = Math.abs(info.velocity.y / info.velocity.x)

          if (angle > 0.5) {
            updated = current
          } else if (
            info.velocity.x > DRAG_VELOCITY_THRESHOLD ||
            (info.velocity.x >= 0 && info.offset.x > DRAG_OFFSET_THRESHOLD)
          ) {
            updated = Math.max(0, current - 1)
          } else if (
            info.velocity.x < -DRAG_VELOCITY_THRESHOLD ||
            (info.velocity.x <= 0 && info.offset.x < -DRAG_OFFSET_THRESHOLD)
          ) {
            updated = Math.min(items - 1, current + 1)
          }

          animate(x, -1 * (updated * width), {
            damping: 50,
            mass: 3,
            stiffness: 500,
            type: 'spring',
          })

          return updated
        })
      }}
      style={{
        maxWidth: `${width}px`,
        minWidth: `${width}px`,
        x,
      }}
    >
      {item(data, { inFocus, index, items, width, x })}
    </motion.div>
  )
}

export default Item

Item.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  item: PropTypes.func.isRequired,
  items: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
}
