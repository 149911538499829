import { IconButton } from '@material-ui/core'
import {
  AccessTime,
  ArrowRight,
  CalendarTodayOutlined,
  TranslateOutlined,
} from '@material-ui/icons'
import { useMotionValue, useTransform } from 'framer-motion'
import Image from 'next/image'
import Link from 'next/link'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import LinesEllipsis from 'react-lines-ellipsis'

import useTime from '../../../hooks/use-time'
import { H5, S05, S1, Text } from '../../../styles/typography'
import broadcastUtils from '../../../utils/broadcast'
import getCurrencySymbol from '../../../utils/currency-symbol'
import dateParser from '../../../utils/date-time'
import dimensions from '../../../utils/dimensions'
import computeFee from '../../../utils/fee'
import { ResumeClassEl } from '../elements/resume-class'

const ResumeClass = ({
  data: {
    key,
    id,
    cover,
    name,
    artist,
    url,
    type,
    medium,
    chapterIndex,
    lessonIndex,
    currentContent,
  },
  params: { index, width, x },
}) => {
  const temp = useMotionValue(0)

  const input = [-width * (index + 1), -width * (index + 0.5), -width * index]
  const output = [0, 0, 1]
  const opacity = useTransform(x || temp, input, output)

  return (
    <Link href={url}>
      <a>
        <ResumeClassEl key={key || id} hideFee={true} style={{ opacity }}>
          <div className='content'>
            <div className='cover'>
              {cover && (
                <Image
                  height={dimensions.dw(60)}
                  quality={50}
                  src={cover}
                  width={dimensions.dw(60)}
                />
              )}
              {type !== 'recorded' && (
                <S1 className='type'>{broadcastUtils.getByType(type)?.name}</S1>
              )}
            </div>
            <LinesEllipsis component={Text} maxLine='2' text={name} />
            <S05 className='artist'>
              By <span>{artist}</span>
            </S05>
            <div className='info'>
              <div className='row'>
                <TranslateOutlined />
                <S05>{medium || `English`}</S05>
              </div>
              {type === 'recorded' && (
                <>
                  <div className='row'>
                    <S05>Chapter {chapterIndex + 1}</S05>
                  </div>
                  <div className='row'>
                    <S05>Lesson {lessonIndex + 1}</S05>
                  </div>
                </>
              )}
              {type !== 'recorded' && (
                <div className='row'>
                  <S05>Title: {currentContent.title}</S05>
                </div>
              )}
            </div>
            <div className='row'>
              <div>Resume Class</div>
              <IconButton>
                <ArrowRight />
              </IconButton>
            </div>
          </div>
        </ResumeClassEl>
      </a>
    </Link>
  )
}

export default ResumeClass

ResumeClass.propTypes = {
  data: PropTypes.shape({
    artist: PropTypes.string.isRequired,
    canSellRecording: PropTypes.bool.isRequired,
    cover: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
    discount: PropTypes.number.isRequired,
    endTime: PropTypes.number.isRequired,
    fee: PropTypes.number.isRequired,
    id: PropTypes.string,
    key: PropTypes.string,
    medium: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    startTime: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
  params: PropTypes.shape({
    index: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    x: PropTypes.any.isRequired,
  }).isRequired,
}
