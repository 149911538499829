import { S05, Text } from '../../../../../styles/typography'
import features from '../config/features'
import { FeatureEl } from '../elements'

const Features = () => {
  return (
    <div className='features'>
      {features.map(({ key, graphic, title, text }) => (
        <FeatureEl key={key} graphic={graphic}>
          <div className='graphic' />
          <div className='details'>
            <Text className='title'>{title}</Text>
            <S05 className='text'>{text}</S05>
          </div>
        </FeatureEl>
      ))}
    </div>
  )
}

export default Features
